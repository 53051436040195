import { format } from 'date-fns';
import * as Yup from 'yup';

import { optionSchema } from './studentProfileonBoarding.scehma';

const phoneRegExp = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
const applicationDetailsSchema = Yup.object().shape(
  {
    appId: Yup.number(),
    applicationTypes: Yup.array().of(Yup.string()),
    applicationIds: Yup.array().of(Yup.number()),
    deferralStatus: Yup.array().of(Yup.string()).optional(),
    conditionStatuses: Yup.array().of(Yup.string()).optional(),
    isOnshore: Yup.array(Yup.boolean()).optional().nullable(),
    appAggregatorSentDateFrom: Yup.date()
      .typeError(`Enter a valid date`)
      .max(
        Yup.ref(`appAggregatorSentDateTo`),
        ({ max }) =>
          `Date needs to be before ${format(new Date(max), `dd/MM/yyyy`)}`,
      ),
    appAggregatorSentDateTo: Yup.date()
      .typeError(`Enter a valid date`)
      .min(
        Yup.ref(`appAggregatorSentDateFrom`),
        ({ min }) =>
          `Date needs to be after ${format(new Date(min), `dd/MM/yyyy`)}`,
      ),
    appInstituteSentDateFrom: Yup.date()
      .typeError(`Enter a valid date`)
      .max(
        Yup.ref(`appInstituteSentDateTo`),
        ({ max }) =>
          `Date needs to be before ${format(new Date(max), `dd/MM/yyyy`)}`,
      ),
    appInstituteSentDateTo: Yup.date()
      .typeError(`Enter a valid date`)
      .min(
        Yup.ref(`appInstituteSentDateFrom`),
        ({ min }) =>
          `Date needs to be after ${format(new Date(min), `dd/MM/yyyy`)}`,
      ),
    lastDateFeePaymentFrom: Yup.date()
      .typeError(`Enter a valid date`)
      .max(
        Yup.ref(`lastDateFeePaymentTo`),
        ({ max }) =>
          `Date needs to be before ${format(new Date(max), `dd/MM/yyyy`)}`,
      ),
    lastDateFeePaymentTo: Yup.date()
      .typeError(`Enter a valid date`)
      .min(
        Yup.ref(`lastDateFeePaymentFrom`),
        ({ min }) =>
          `Date needs to be after ${format(new Date(min), `dd/MM/yyyy`)}`,
      ),
    preVisaStatuses: Yup.array().of(Yup.string()).nullable().notRequired(),
    admitPreference: Yup.array().of(Yup.string()).nullable().notRequired(),
    depositBlocker: Yup.array().of(Yup.string()).nullable().notRequired(),
  },
  [[`appAggregatorSentDateFrom`, `appAggregatorSentDateTo`]],
);

const studentDetailsSchema = Yup.object().shape({
  studentName: Yup.string().nullable(),
  studentEmail: Yup.string().email().optional(),
  studentId: Yup.number().optional(),
  studentNames: Yup.array().of(Yup.string()).optional(),
  studentEmails: Yup.array().of(Yup.string().email()).optional(),
  studentIds: Yup.array().of(Yup.number()).optional(),
  leadStages: Yup.array().of(Yup.string()).nullable().optional(),
  leadStage: Yup.string().optional().nullable(),
  searchByKey: Yup.string().optional(),
  searchByKeys: Yup.array().of(Yup.string()).optional(),
});

const appAndPaymentStatusSchema = Yup.object().shape({
  applicationStages: Yup.array().of(Yup.string()).optional(),
});

const paymentStatusSchema = Yup.object().shape({
  paymentStatuses: Yup.array().of(Yup.string().nullable()).optional(),
});

const otherFiltersSchema = Yup.object().shape({
  otherFilters: Yup.boolean().optional().nullable(),
});

const universityAndCourseSchema = Yup.object().shape({
  universityName: Yup.string().nullable(),
  programName: Yup.string().nullable(),
  universityNames: Yup.array().of(Yup.string()).optional(),
  programNames: Yup.array().of(Yup.string()).optional(),
});

const countrySchema = Yup.object().shape({
  countries: Yup.array().of(Yup.string()),
});

const appliedBySchema = Yup.object().shape({
  appliedByName: Yup.string().optional(),
  appliedById: Yup.number().optional(),
  appliedByIds: Yup.array().of(Yup.number()).optional(),
});

const aggregatorManagerSchema = Yup.object().shape({
  aggregatorManagerName: Yup.string().optional(),
  aggregatorManagerId: Yup.number().optional(),
  aggregatorManagerIds: Yup.array().of(Yup.number()).optional(),
});

const referenceIdSchema = Yup.object().shape({
  studentReferenceId: Yup.string().optional(),
  studentReferenceIds: Yup.array().of(Yup.string()).optional(),
});

const intakeSchema = Yup.object().shape({
  intakes: Yup.array().of(Yup.string()).optional(),
  intakeGroup: Yup.array().of(Yup.string()).optional(),
});

const dueDateSchema = Yup.object().shape(
  {
    offerDueDateFrom: Yup.date()
      .typeError(`Enter a valid date`)
      .max(
        Yup.ref(`offerDueDateTo`),
        ({ max }) =>
          `Date needs to be before ${format(new Date(max), `dd/MM/yyyy`)}`,
      ),
    offerDueDateTo: Yup.date()
      .typeError(`Enter a valid date`)
      .min(
        Yup.ref(`offerDueDateFrom`),
        ({ min }) =>
          `Date needs to be after ${format(new Date(min), `dd/MM/yyyy`)}`,
      ),
  },
  [[`offerDueDateFrom`, `offerDueDateTo`]],
);
const lastUpdatedSchema = Yup.object().shape(
  {
    lastUpdatedFrom: Yup.date()
      .typeError(`Enter a valid date`)
      .max(
        Yup.ref(`lastUpdatedTo`),
        ({ max }) =>
          `Date needs to be before ${format(new Date(max), `dd/MM/yyyy`)}`,
      ),
    lastUpdatedTo: Yup.date()
      .typeError(`Enter a valid date`)
      .min(
        Yup.ref(`lastUpdatedFrom`),
        ({ min }) =>
          `Date needs to be after ${format(new Date(min), `dd/MM/yyyy`)}`,
      ),
  },
  [[`lastUpdatedFrom`, `lastUpdatedTo`]],
);
const studentFilterSchema = Yup.object().shape({
  studentName: Yup.string().optional(),
  searchByKey: Yup.string().optional(),
  searchByKeys: Yup.array().of(Yup.string()).optional(),
  studentEmail: Yup.string().email().optional(),
  studentId: Yup.number().optional(),
  studentNames: Yup.array().of(Yup.string()).optional(),
  studentEmails: Yup.array().of(Yup.string().email()).optional(),
  studentIds: Yup.array().of(Yup.number()).optional(),
  studentMobile: Yup.string().optional(),
  studentMobiles: Yup.array().of(Yup.string()).optional(),
  universityName: Yup.string().optional(),
  universityNames: Yup.array().of(Yup.string()).optional(),
  programName: Yup.string().optional(),
  programNames: Yup.array().of(Yup.string()).optional(),
  leadQuality: Yup.string().optional(),
  leadQualities: Yup.array().of(Yup.string()).optional(),
  followUpDateFrom: Yup.date().optional(),
  followUpDateTo: Yup.date().optional(),
  leadStages: Yup.array().of(Yup.string()).nullable().optional(),
  leadStage: Yup.string().optional().nullable(),
  visaStatuses: Yup.array().of(Yup.string()).nullable().optional(),
  selectAtRiskStudents: Yup.array().of(Yup.string()).optional().nullable(),
  studentCounsellors: Yup.array().of(Yup.string()).optional(),
  intakeRange: Yup.array().of(Yup.string()).nullable(),
  leadSources: Yup.array().of(Yup.mixed()).nullable(),
  contactNumber: Yup.string().optional(),
  contactNumbers: Yup.array().of(Yup.string()).optional(),
});

const shortlistRequestFilterSchema = Yup.object().shape({
  shortListRequestStatus: Yup.array().of(Yup.string()).optional(),
  shortListRequestCountryGroups: Yup.array().of(Yup.string()).nullable(),
});

const dashboardFiltersSchema = Yup.object().shape({
  dashboardBucket: optionSchema.nullable(),
});

const subAgentFiltersSchema = Yup.object().shape({
  subAgentIds: Yup.array().of(Yup.mixed()),
});

const dateFilterSchema = Yup.object().shape({
  submittedToInstituteMonth: Yup.mixed(),
  studentCreatedMonth: Yup.mixed(),
});

export {
  applicationDetailsSchema,
  studentDetailsSchema,
  appAndPaymentStatusSchema,
  universityAndCourseSchema,
  countrySchema,
  appliedBySchema,
  aggregatorManagerSchema,
  referenceIdSchema,
  intakeSchema,
  dueDateSchema,
  studentFilterSchema,
  lastUpdatedSchema,
  paymentStatusSchema,
  shortlistRequestFilterSchema,
  otherFiltersSchema,
  dashboardFiltersSchema,
  dateFilterSchema,
  subAgentFiltersSchema,
};
